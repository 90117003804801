.product-title{
    font-family:'Times New Roman';
    font-size: 23px;
    font-weight: 600;
}

.product-detail{
    padding-left: 15px;
    font-family:'Times New Roman';
    font-size: 16px;
}
.hr-line{
    margin: 8px 0;
}
.image-holder{
    align-items: center;
}