.contact-section {
    background-color: #fff;
    padding: 12px 5%;
    font-family: 'Times New Roman';
    margin-bottom: 10px;
}
.contact-title{
    color: #141413;
    font-size: 1.5rem;
    font-weight: 800;
    margin-left: 25px;
}

.contact-head{
    color: #141413;
    font-size: 1.2rem;
    font-weight: 400;
    margin-left:25px;
    margin-top: 25px;
}

.contact-detail{
    color: #141413;
    font-size: 1rem;
    font-weight: 400;
    margin-left: 28px;
    margin-top: 40px;
}
.icons{
    font-size: 1.5rem;
    text-decoration: none;
    color: #000;
}
.openpop{
    margin: 10px 0 0 auto;
    height: 5rem;
}