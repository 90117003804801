.Items{
    margin-top: 5px;
padding: 10px 6%;
    background-color: #fff;
}
.Item{
    display: inline-flex;
    flex-direction: column;
    width: 20%;
    border: none;
    margin: 0px ;
}

.Item :hover{
    border-radius: 4px;
    border: 1px rgb(246, 209, 101) solid;
}
.item-card{
    border-radius: 4px;
    border: 1px rgb(255, 255, 255) solid;
}
.item-card :hover{
    border-radius: 4px;
    border: 1px rgb(255, 255, 255) solid;
}
.all-item{
    color: #000000;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    padding: 0 16px;
    font-size: 1.25rem;
    font-weight: 600;
}
.item-text{
    color: #000000;
    padding: 0 16px;
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 18px;
    margin-left: 25px;
    font-family: 'Times New Roman';
}
.item-body{
    padding: 0 ;
}
.item-title :hover{
    border: 0px;
}
.item-title{
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin-top: 12px;
}
