.about-section {
    background-color: #fff;
    margin: 0 5%;
    width: 90%;
    padding: 15px 0;
}
.about-text{
    color: #141413;
    padding: 0 16px;
    font-size: 1.25rem;
    font-weight: 800;
    margin-left: 25px;
    font-family: 'Times New Roman';
}
.about-para{
    color: #141413;
    padding: 0 16px;
    font-size: 1rem;
    margin-top: 18px;
    margin-left: 25px;
    font-family: 'Times New Roman';
}